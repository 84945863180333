<template>
  <a-select v-model="query.admin_id"
      :placeholder="placeholder"
      show-search
      allowClear
      :options="sellerOpts"
      style="width: 100%"
      @blur="onBlur"
      @search="onSearch"
      @popupScroll="popupScroll"
      :filter-option="false"
    >
  </a-select>
</template>

<script>
import { postForm } from '@/api'

const idList = ['crop_id', 'brand_id', 'region_id', 'area_id', 'company_id'];
export default {
  name: 'SelectSeller',
  props: {
    placeholder: {
      type: String,
      default() {
        return ''
      }
    },
    query: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      sellerOpts: [],
    }
  },
  watch: {
    immediate:true,
    query(n, o) {
      this.diffKey = [];
      this.queryMap = [];
      idList.forEach((k,i) => {
        this.queryMap[i] = {name: k, value: n[k]};
        if (n[k] != o[k]) {
          this.diffKey.push(k);
        }
      });
      if (this.diffKey.length) {
        const q = (this.queryMap || []).reduce((s,o) => ({...s, [o.name]: o.value}), {});
        this.updateData();
      }
    }
  },
  methods: {
    onBlur() {
      this.searchVal = null;
      const {admin_id} = this.query;
      if (admin_id) {
        this.sellerOpts =loadPart(this.queryData, admin_id);
      } else {
        this.sellerOpts = loadMore(this.queryData);
      }
    },
    async updateData() {
      const q = idList.reduce((s,o) => ({
        ...s,
        [o]: this.query[o],
      }), {});
      const {admin_id} = this.query;
      const idx = (this.queryMap || []).findIndex(o => o.value);
      if (idx == -1 && !admin_id) {
        this.queryData = [...this.totalData];
      } else {
        this.queryData = await this.getSeller(q);
      }
      if(admin_id) {
        this.sellerOpts =loadPart(this.queryData, admin_id);
      } else {
        this.sellerOpts = loadMore(this.queryData);
      }
    },
    getSeller(q = {}) {
      return postForm('/api/option/admin', q).then(({data}) => {
        if (!data) {
          return;
        }
        const sellerOpts = data.map(o => ({key: String(o.id), value: o.id, label: o.name}));
        return sellerOpts;
      });
    },
    searchValue(value) {
      this.searchVal = value;
      const list = this.queryData;
      if (!value) {
        this.sellerOpts = loadMore(list);
        return;
      }
      const data = []
       list.forEach(item => {
         if (item.label.toUpperCase().indexOf(value.toUpperCase()) > -1) {
          data.push(item)
         }
      })
      this.sellerOpts = loadMore(data);
    },
    onSearch(value){
      if (!this.timer) {
        this.timer = setTimeout(() => {
        this.searchValue(value)
        clearTimeout(this.timer);
        this.timer = null
        },300)
       }
    },
    popupScroll(e){
      if(this.searchVal) {
        return;
      }
      if (!this.timerS) {
        this.timers = setTimeout(() => {
        const {target} = e;
        const scrllHeight = target.scrollHeight - target.scrollTop;
        const clientHeight = target.clientHeight;
        const diff = 50;
        const sells = this.sellerOpts;
        if(target.scrollTop < diff){
          const first = this.sellerOpts[0];
          const idx = this.queryData.findIndex(o => o.value === first.value);
          const before = loadBefore(this.queryData, idx);
          this.sellerOpts = [ ...before, ...this.sellerOpts];
        }
        if(scrllHeight - clientHeight < diff){
          const last = this.sellerOpts[sells.length-1];
          const idx = this.queryData.findIndex(o => o.value === last.value);
          const more = loadMore(this.queryData, idx+1);
          this.sellerOpts = [...this.sellerOpts, ...more];
        }
        clearTimeout(this.timers);
        this.timers = null
        },500)
      }
    }
  },
  async created() {
    this.totalData = await this.getSeller();
    this.updateData();
  }
}


const max = 50;
function loadPart(total, id) {
  if (!total) {
    return [];
  }
  const idx = total.findIndex(o => o.value === id);
  const list = total.slice(Math.max(idx-max/2, 0), Math.min(idx+max/2, total.length));
  return list;
}

function loadBefore(total, idx=0) {
  const list = total && total.slice(Math.max(idx-max, 0), idx);
  return list;
}
function loadMore(total, idx=0) {
  const list = total && total.slice(idx, idx + max);
  return list;
}
</script>

<style scoped lang="less">
</style>
